<template>
  <v-container fluid>
    <v-tabs v-model="tab" centered style="font-size: 12px" class="mt-2">
      <v-tab>Formulir Klaim Rawat Jalan</v-tab>
      <v-tab>Lembar Program Terapi</v-tab>
      <v-tab>Tindakan Uji Fungsi</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <klaim-rawat-jalan></klaim-rawat-jalan>
      </v-tab-item>
      <v-tab-item>
        <lembar-program-terapi></lembar-program-terapi>
      </v-tab-item>
      <v-tab-item>
        <lembar-hasil-tindakan></lembar-hasil-tindakan>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Master from '../../data/formulir-klaim-rawat-jalan'
import KlaimRawatJalan from './KlaimRawatJalan'
import LembarProgramTerapi from './LembarProgramTerapi'
import LembarHasilTindakan from './LembarHasilTindakan'

// import SmartWidget from '@/components/SmartWidget.vue'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  components: {
    KlaimRawatJalan,
    LembarProgramTerapi,
    LembarHasilTindakan
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      snack_color: 'green',
      url_emr: 'http://emr.rs-syafira.com/',
      master: Master,
      tab: '',

      user: JSON.parse(localStorage.getItem('user'))
    }
  }
}
</script>
