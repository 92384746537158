export default {
  demografi: {
    nama_pasien: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Pasien'
    },
    no_rekam_medis: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No. RM'
    },
    tanggal_lahir: {
      widget: 'wdate',
      data: null,
      col: 3,
      label: 'Tanggal Lahir'
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No Folio'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 6
    }
  },
  inputan_terapi: {
    diagnosa: {
      widget: 'wtextarea',
      label: 'Diagnosa'
    },
    permintaan_terapi: {
      widget: 'wtextarea',
      label: 'Permintaan Terapi'
    }
  }
}
