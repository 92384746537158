<template>
  <div class="m-1">
    <v-row class="mt-1">
      <v-col cols="12" class="mt-2" md="6" lg="6" sm="6">
        <v-card color="teal darken-1 mb-2 p-2" dark>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"

                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="p-3" elevation="5">
          <v-card-text class="mt-1">
            <!-- <v-btn
              @click="fillFromSebelumnya()"
              color="cyan"
              dark
              medium
              class="mb-0"
            >
              Ambil Data Sebelumnya
            </v-btn> -->
          </v-card-text>
          <v-card-text class="mt-0">
            <v-row class="mb-0">
              <v-col
                v-for="(v, i) in master.inputan_dokter.pertama"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_dokter.pertama[i] = e)"
                  :value="data.inputan_dokter.pertama[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              Tata laksana KFR (ICD 9 CM)
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_dokter.tata_laksana_kfr"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="
                    (e) => (data.inputan_dokter.tata_laksana_kfr[i] = e)
                  "
                  :value="data.inputan_dokter.tata_laksana_kfr[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />

            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_dokter.kedua"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_dokter.kedua[i] = e)"
                  :value="data.inputan_dokter.kedua[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">Tanggal Evaluasi</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_dokter.tanggal_evaluasi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="
                    (e) => (data.inputan_dokter.tanggal_evaluasi[i] = e)
                  "
                  :value="data.inputan_dokter.tanggal_evaluasi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />

            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_dokter.ketiga"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_dokter.ketiga[i] = e)"
                  :value="data.inputan_dokter.ketiga[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <div class="py-1"></div>
            <v-btn
              @click="dialogShow = true"
              color="primary"
              medium
              class="btn-block"
            >
              Simpan
            </v-btn>
            <v-btn
              class="mt-1 btn-block"
              @click="daftarkan"
              dark
              color="red lighten-1"
              >Daftarkan Pasien</v-btn
            >
            <div class="py-2"></div>
            <dialog-confirm
              :show-dialog="dialogShow"
              :negative-button="dialogActionNBtn"
              :positive-button="dialogActionPBtn"
              :disabled-negative-btn="dialogDisableNBtn"
              :disabled-positive-btn="dialogDisablePBtn"
              :progress="dialogProgress"
              :title="dialogTitle"
              :message="dialogMessage"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-2" md="6" lg="6" sm="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="6">
                <v-text-field
                  @blur="getDataListKlaim"
                  v-model="searchKlaim"
                  dense
                  label="Pencarian"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="6">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" @click="initPermintaanPreviosPage"
                        >Previous</a
                      >
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        v-html="permintaan_pagination_container"
                      ></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" @click="initPermintaanNextPage"
                        >Next</a
                      >
                    </li>
                  </ul>
                </nav>
              </v-col>
            </v-row>
            <div class="list-group" v-for="(itemList, i) of listKlaim" :key="i">
              <div
                class="list-group-item list-group-item-action flex-column align-items-start mb-1"
              >
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                    <b>
                      {{
                        itemList.pemeriksaan.inputan_dokter.pertama.anamnesa
                      }}</b
                    >
                  </p>
                </div>
                <p class="mb-2">
                  {{
                    itemList.pemeriksaan.inputan_dokter.pertama.diagnosa_medis
                  }}
                </p>

                <!-- {{ itemList.pemeriksaan.inputan_dokter.tanggal_evaluasi }} -->
                <table class="table table-bordered table-striped table-sm" width="100%">
                  <thead class="table-success">
                    <tr>
                      <th
                        width="15%"
                        style="text-align: center; vertical-align: middle"
                      >
                        Ke Berapa
                      </th>
                      <th style="text-align: center; vertical-align: middle">
                        Tanggal Evaluasi
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item2, index2) of filterobject(
                        itemList.pemeriksaan?.inputan_dokter.tanggal_evaluasi
                      )"
                      :key="index2"
                    >
                      <td style="text-align: center; vertical-align: middle">
                        {{ index2 }}
                      </td>
                      <td style="text-align: center; vertical-align: middle">
                        {{ item2 | moment("DD MMMM YYYY") }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="table-danger">
                    <tr>
                      <td colspan="2" style="text-align: center;">
                        <b>Tanggal Pelayanan : </b> {{ itemList.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="mb-2 mt-1">
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn
                        color="primary"
                        @click="copyItem(itemList)"
                        class="btn-block"
                        medium
                        dark
                        >Copy</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn
                        color="red"
                        @click="cetaknya(itemList, '')"
                        class="btn-block"
                        medium
                        dark
                        >View</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn
                        color="green"
                        @click="cetaknya(itemList, 'download')"
                        class="btn-block"
                        medium
                        dark
                        >Download</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snack_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Master from '../../data/formulir-klaim-rawat-jalan'
import SmartWidget from '@/components/SmartWidget.vue'
// import { mapActions, mapMutations } from 'vuex'
import Dialog from '@/components/Dialog'
import { mapActions } from 'vuex'
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'

export default {
  components: {
    SmartWidget,
    DialogConfirm: Dialog
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      snack_color: 'green',
      url_emr: 'http://emr.rs-syafira.com/',
      master: Master,
      tab: '',
      searchKlaim: '',
      permintaans_page: 1,
      permintaans_per_page: 9,
      permintaan_pagination_container: '0-0 of 0',
      permintaans_total: '',
      listKlaim: [],
      user: JSON.parse(localStorage.getItem('user')),
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: ''
        },
        inputan_dokter: {
          pertama: {
            tanggal_pelayanan: '',
            anamnesa: '',
            pemeriksaan_fisk_dan_uji_fungsi: '',
            diagnosa_medis: '',
            diagnosa_fungsi: '',
            pemeriksaan_penunjang: '',
            problem: ''
          },
          tata_laksana_kfr: {
            terapi_modalitas: '',
            terapi_latihan: '',
            ortesa_protesa_medikamnetosa: ''
          },
          kedua: {
            frekuensi: '',
            anjuran: ''
          },
          tanggal_evaluasi: {
            i: '',
            ii: '',
            iii: '',
            iv: '',
            v: '',
            vi: ''
          },
          ketiga: {
            subjek_penyakit_akibat_kerja: ''
          }
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    console.log(this.master)
    if (this.$route.query.no_rm != null) {
      this.getKlaimRawatJalan(this.folioYangDipakai)
      //   }, 1000)
      this.getDataListKlaim()
      // }
      this.getDataPasien(this.$route.query.no_rm)
    }
  },
  methods: {
    filterobject (e) {
      return Object.fromEntries(
        Object.entries(e).filter(([key, value]) => value !== '')
      )
    },

    romanToInt (roman) {
      const romanNumerals = {
        I: 1,
        V: 5,
        X: 10,
        L: 50,
        C: 100,
        D: 500,
        M: 1000
      }

      let total = 0
      let prevValue = 0

      for (let i = roman.length - 1; i >= 0; i--) {
        const currentValue = romanNumerals[roman[i]]

        if (currentValue >= prevValue) {
          total += currentValue
        } else {
          total -= currentValue
        }

        prevValue = currentValue
      }

      return total
    },
    daftarkan () {},
    ...mapActions(['postFormKlaimRawatJalan']),
    copyItem (item) {
      this.data.inputan_dokter.pertama =
        item.pemeriksaan.inputan_dokter.pertama
      this.data.inputan_dokter.tata_laksana_kfr =
        item.pemeriksaan.inputan_dokter.tata_laksana_kfr
      this.data.inputan_dokter.kedua = item.pemeriksaan.inputan_dokter.kedua
      this.data.inputan_dokter.tanggal_evaluasi =
        item.pemeriksaan.inputan_dokter.tanggal_evaluasi
      this.data.inputan_dokter.ketiga = item.pemeriksaan.inputan_dokter.ketiga
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          console.log('HASIL RESPONSE ' + response)

          // this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
          // this.folioYangDipakai = JSON.parse(
          //   localStorage.getItem('folioIdYangDipakai')
          // )
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getDataListKlaim () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/list-klaim-by-pag',
        {
          rm: this.$route.query.no_rm,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          pls: this.searchKlaim
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          const pagination = results.pagination
          this.permintaan_pagination_container = pagination
          this.permintaans_total = results.total_pages
          this.listKlaim = results.list
          // console.log(response)
        },
        'JSON'
      )
    },
    cetaknya (itemList, view) {
      // console.log(this.folioYangDipakai)
      window
        .open(
          baseUrlEmr +
            `cetak-word-emr/cetak-klaim-rawat-jalan?no_rm=${itemList.no_rm}&folio_id=${itemList.folio_id}&registration_id=${itemList.registration_id}&view=` +
            view,
          '_blank'
        )
        .focus()
    },
    postSave () {
      this.dialogProgress = true
      this.dialogDisableNBtn = true
      this.dialogDisablePBtn = true
      this.dialogMessage = 'Mohon tunggu sebentar, sedang menyimpan data...'
      console.log(this.data)

      this.postFormKlaimRawatJalan({
        data: this.data,
        created_by: this.user.employee_id
      }).then((res) => {
        this.dialogProgress = false
        this.dialogMessage = res.msg
        this.getDataListKlaim()
        setTimeout(() => {
          this.dialogShow = false
          // this.backButton()
          this.dialogDisableNBtn = false
          this.dialogDisablePBtn = false

          this.dialogMessage = 'Simpan Form Sekarang Ya?'
        }, 1000)
      })
      this.getDataListKlaim()
    },
    getKlaimRawatJalan (v) {
      apiKedua
        .get(
          'emr/simpan-klaim-rawat-jalan?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          // console.log(response);
          if (response) {
            this.data.inputan_dokter.pertama = response.inputan_dokter.pertama
            this.data.inputan_dokter.tata_laksana_kfr =
              response.inputan_dokter.tata_laksana_kfr
            this.data.inputan_dokter.kedua = response.inputan_dokter.kedua
            this.data.inputan_dokter.tanggal_evaluasi =
              response.inputan_dokter.tanggal_evaluasi
            this.data.inputan_dokter.ketiga = response.inputan_dokter.ketiga
          }
          // console.log(response)
        })
    },
    getKlaimRawatJalanSebelumnya (v) {
      apiKedua
        .get(
          'emr/get-sebelum-klaim-rawat-jalan?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          console.log(res.data)
          if (res.data.con === true) {
            this.data.inputan_dokter.pertama = response.inputan_dokter.pertama
            this.data.inputan_dokter.tata_laksana_kfr =
              response.inputan_dokter.tata_laksana_kfr
            this.data.inputan_dokter.kedua = response.inputan_dokter.kedua
            this.data.inputan_dokter.tanggal_evaluasi =
              response.inputan_dokter.tanggal_evaluasi
            this.data.inputan_dokter.ketiga = response.inputan_dokter.ketiga
          }
          console.log(response)
          setTimeout(() => {
            this.snack_text = res.data.msg
            this.snack_color = 'green'
            this.snackbar = true
            this.dialogShow = false
            // this.backButton()
            this.dialogDisableNBtn = false
            this.dialogDisablePBtn = false

            this.dialogMessage = 'Simpan Form Sekarang Ya?'
          }, 300)
        })
    },
    fillFromSebelumnya () {
      this.getKlaimRawatJalanSebelumnya(this.folioYangDipakai)
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getDataListKlaim()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getDataListKlaim()
    }
  }
}
</script>
