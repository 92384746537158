<template>
  <v-container fluid class="mt-3">
    <v-row>
      <v-col cols="12" md="6" lg="6" sm="6">
        <v-card color="teal darken-1 mb-2 p-2" dark>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="pl-6 pb-1 pr-6" elevation="5">
          <v-card-text class="mt-3 pt-7">
            <v-btn
              @click="fillFromKlaim()"
              color="cyan"
              dark
              medium
              class="mb-10"
            >
              Ambil Dari Klaim
            </v-btn>
            &nbsp;
            <v-btn
              @click="fillFromSebelumnya()"
              color="cyan"
              medium
              dark
              class="mb-10"
            >
              Ambil Data Sebelumnya
            </v-btn>
            <v-row class="mt-0 mb-0">
              <h6 class="mt-0 mb-0 pt-0 pb-3">
                Instrumen Uji Fungsi / Prosedur KFR :
              </h6>
              <v-col
                v-for="(v, i) in master.instrumen"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.instrumen[i] = e)"
                  :value="data.instrumen[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="dialogShow = true"
              color="primary"
              medium
              class="mb-0"
            >
              Simpan
            </v-btn>
            <!-- &nbsp;
            <v-btn
              @click="cetaknya('download')"
              color="primary"
              medium
              class="mb-0"
            >
              Download
            </v-btn>
            &nbsp;
            <v-btn @click="cetaknya('')" color="primary" medium class="mb-0">
              Lihat
            </v-btn> -->
            <dialog-confirm
              :show-dialog="dialogShow"
              :negative-button="dialogActionNBtn"
              :positive-button="dialogActionPBtn"
              :disabled-negative-btn="dialogDisableNBtn"
              :disabled-positive-btn="dialogDisablePBtn"
              :progress="dialogProgress"
              :title="dialogTitle"
              :message="dialogMessage"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" sm="6">
        <v-card class="p-2" elevation="5">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="6">
                <v-text-field
                  @blur="getTindakanHasil"
                  v-model="searchTindakan"
                  dense
                  label="Pencarian"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="6">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" @click="initPermintaanPreviosPage"
                        >Previous</a
                      >
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        v-html="permintaan_pagination_container"
                      ></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" @click="initPermintaanNextPage"
                        >Next</a
                      >
                    </li>
                  </ul>
                </nav>
              </v-col>
            </v-row>
            <div
              class="list-group"
              v-for="(itemList, i) of listTindakan"
              :key="i"
            >
              <div
                class="list-group-item list-group-item-action flex-column align-items-start mb-1"
              >
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                    <b>
                      Kesimpulan : </b
                    > {{ itemList.pemeriksaan.instrumen.hasil_yang_dapat }}
                  </p>
                </div>
                <p class="mb-2">
                  {{ itemList.pemeriksaan.instrumen.kesimpulan }}
                  <b>Tanggal Pelayanan : </b>
                  {{ itemList.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}
                </p>
                <div class="mb-2 mt-1">
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn
                        color="primary"
                        class="btn-block"
                        @click="coypItem(itemList)"
                        medium
                        dark
                        >Copy</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn
                        color="red"
                        class="btn-block"
                        @click="cetaknya(itemList, '')"
                        medium
                        dark
                        >View</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn
                        color="green"
                        class="btn-block"
                        @click="cetaknya(itemList, 'download')"
                        medium
                        dark
                        >Download</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snack_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/lembar-hasil-tindakan'
import Dialog from '@/components/Dialog'
import { mapActions } from 'vuex'
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  components: {
    SmartWidget,
    DialogConfirm: Dialog
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      snack_color: 'green',
      master: Master,
      url_emr: 'http://emr.rs-syafira.com/',
      user: JSON.parse(localStorage.getItem('user')),
      searchTindakan: '',
      permintaans_page: 1,
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',
      listTindakan: [],
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: ''
        },
        instrumen: {
          diagnosa_fungsional: '',
          diagnosa_medis: '',
          hasil_yang_dapat: '',
          kesimpulan: '',
          rekomendasi: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getHasilTindakanUjiFungsi()
      this.getTindakanHasil()
    }
  },
  methods: {
    ...mapActions(['postHasilTindakanUjiFungsi']),
    coypItem (item) {
      // if (res.data.con === true) {
      this.data.instrumen = item.pemeriksaan.instrumen
      // console.log(response)
      // }
    },
    getTindakanHasil () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/list-tindakan-by-pag',
        {
          rm: this.$route.query.no_rm,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          pls: this.searchTindakan
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          const pagination = results.pagination
          this.permintaan_pagination_container = pagination
          this.permintaans_total = results.total_pages
          this.listTindakan = results.list
          // console.log(response)
        },
        'JSON'
      )
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          console.log('HASIL RESPONSE ' + response)

          // this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
          // this.folioYangDipakai = JSON.parse(
          //   localStorage.getItem('folioIdYangDipakai')
          // )
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    cetaknya (item, view) {
      // console.log(this.$route.query)
      window
        .open(
          baseUrlEmr +
            `cetak-word-emr/cetak-uji-fungsi-rehab?no_rm=${item.no_rm}&folio_id=${item.folio_id}&registration_id=${item.registration_id}&view=` +
            view,
          '_blank'
        )
        .focus()
    },
    postSave () {
      this.dialogProgress = true
      this.dialogDisableNBtn = true
      this.dialogDisablePBtn = true
      this.dialogMessage = 'Mohon tunggu sebentar, sedang menyimpan data...'
      console.log(this.data)

      this.postHasilTindakanUjiFungsi({
        data: this.data,
        created_by: this.user.employee_id
      }).then((res) => {
        this.dialogProgress = false
        this.dialogMessage = res.msg
        this.getTindakanHasil()
        setTimeout(() => {
          this.snack_text = res.data.msg
          this.snackbar = true
          this.dialogShow = false
          // this.backButton()
          this.dialogDisableNBtn = false
          this.dialogDisablePBtn = false

          this.dialogMessage = 'Simpan Form Sekarang Ya?'
        }, 1000)
      })
    },
    getHasilTindakanUjiFungsi (v) {
      // console.table(v)
      apiKedua
        .get(
          'emr/simpan-lembar-hasil-tindakan-uji-fungsi?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          // console.log(response);
          if (response) {
            this.data.instrumen = response.instrumen
          }

          console.log(response)
        })
    },
    getHasilTindakanUjiFungsiSebelumnya (v) {
      // console.table(v)
      apiKedua
        .get(
          'emr/get-sebelum-lembar-hasil-tindakan-uji-fungsi?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          // console.log(res)
          // console.log(res)
          // console.log(res)
          // console.log(res)
          // console.log(response)
          // console.log(response)
          if (res.data.con === true) {
            this.data.instrumen = response.instrumen
            console.log(response)
          }
          setTimeout(() => {
            this.snack_text = res.data.msg
            this.snackbar = true
            this.dialogShow = false
            // this.backButton()
            this.dialogDisableNBtn = false
            this.dialogDisablePBtn = false

            this.dialogMessage = 'Simpan Form Sekarang Ya?'
          }, 300)
        })
    },
    getKlaimRawatJalan (v) {
      apiKedua
        .get(
          'emr/simpan-klaim-rawat-jalan?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          console.log(res)
          if (res.data.con === true) {
            const response = res.data.results
            // console.log(response);
            this.data.instrumen.hasil_yang_dapat =
              response.inputan_dokter.pertama.anamnesa
            if (
              typeof response.inputan_dokter.pertama.problem !== 'undefined'
            ) {
              this.data.instrumen.kesimpulan =
                response.inputan_dokter.pertama.problem
            }
            console.log(response)
          }

          setTimeout(() => {
            this.snack_text = res.data.msg
            this.snack_color = 'green'
            this.snackbar = true
            this.dialogShow = false
            // this.backButton()
            this.dialogDisableNBtn = false
            this.dialogDisablePBtn = false

            this.dialogMessage = 'Simpan Form Sekarang Ya?'
          }, 300)
        })
    },
    fillFromKlaim () {
      this.getKlaimRawatJalan()
    },
    fillFromSebelumnya () {
      this.getHasilTindakanUjiFungsiSebelumnya()
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getTindakanHasil()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getTindakanHasil()
    }
  }
}
</script>
