export default {
  demografi: {
    nama_pasien: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Nama Pasien'
    },
    no_rekam_medis: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No. RM'
    },
    tanggal_lahir: {
      widget: 'wdate',
      data: null,
      col: 3,
      label: 'Tanggal Lahir'
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No Folio'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 12
    }
  },
  inputan_dokter: {
    pertama: {
      tanggal_pelayanan: {
        widget: 'wdate',
        label: 'Tanggal Pelayanan'
      },
      anamnesa: {
        widget: 'wtextarea',
        label: 'Anamnesa'
      },
      pemeriksaan_fisk_dan_uji_fungsi: {
        widget: 'wtextarea',
        label: 'Pemeriksaan Fisk Dan Uji Fungsi'
      },
      diagnosa_medis: {
        label: 'Diagnosa Medis',
        widget: 'wtextarea',
        col: 6
      },
      diagnosa_fungsi: {
        label: 'Diagnosa Fungsi',
        widget: 'wtextarea',
        col: 6
      },
      pemeriksaan_penunjang: {
        label: 'Pemeriksaan Penunjang',
        widget: 'wtextarea',
        col: 6
      },
      problem: {
        label: 'Problem',
        widget: 'wtextarea',
        col: 6
      }
    },
    tata_laksana_kfr: {
      terapi_modalitas: {
        widget: 'wtextarea',
        data: null,
        col: 12,
        label: 'Terapi Modalitas'
      },
      terapi_latihan: {
        widget: 'wtextarea',
        data: null,
        col: 12,
        label: 'Terapi Latihan'
      },
      ortesa_protesa_medikamnetosa: {
        widget: 'wtextarea',
        data: null,
        col: 12,
        label: 'Ortesa/Protesa/Medikamnetosa'
      }
    },
    kedua: {
      frekuensi: {
        widget: 'wtext',
        data: null,
        label: 'Frekuensi',
        col: 6,
        property: { attrs: { suffix: 'Kali/Minggu', type: 'number' } }
      },
      anjuran: {
        label: 'Anjuran',
        widget: 'wtextarea',
        col: 6
      }
    },
    tanggal_evaluasi: {
      i: {
        widget: 'wdatenormal',
        data: [],
        col: 4,
        label: 'I.'
      },
      ii: {
        widget: 'wdatenormal',
        data: [],
        col: 4,
        label: 'II.'
      },
      iii: {
        widget: 'wdatenormal',
        data: [],
        col: 4,
        label: 'III.'
      },
      iv: {
        widget: 'wdatenormal',
        data: [],
        col: 4,
        label: 'IV.'
      },
      v: {
        widget: 'wdatenormal',
        data: [],
        col: 4,
        label: 'V.'
      },
      vi: {
        widget: 'wdatenormal',
        data: [],
        col: 4,
        label: 'VI.'
      }
    },
    ketiga: {
      subjek_penyakit_akibat_kerja: {
        label: 'Subjek Penyakit Akibat Kerja',
        widget: 'wradio',
        col: 12,
        data: [
          {
            text: 'Tidak',
            value: 'Tidak'
          },
          {
            text: 'Ya',
            value: null,
            field: 'auto'
          }
        ]
      }
    }
  }
}
