export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 6
    },
    no_rekam_medis: {
      label: 'No. Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: { widget: 'wdate', data: null, col: 3 },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 4
    },

    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 7
    }
  },
  instrumen: {
    hasil_yang_dapat: {
      widget: 'wtextarea',
      data: null,
      label: 'Hasil Yang Didapat'
    },
    kesimpulan: {
      widget: 'wtextarea',
      data: null,
      label: 'Kesimpulan'
    },
    rekomendasi: {
      widget: 'wtextarea',
      data: null,
      label: 'Rekomendasi'
    }
  }
}
