<template>
  <v-container fluid class="mb-2">
    <v-row class="mt-2">
      <v-col cols="12" md="6" lg="6" sm="6">
        <v-card color="teal darken-1 mb-2 p-2" dark>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="p-3" elevation="5">
          <v-card-text class="mt-0">
            <v-btn
              @click="fillFromKlaim()"
              color="cyan"
              dark
              medium
              class="mb-2"
            >
              Ambil Dari Klaim
            </v-btn>
            &nbsp;
            <v-btn
              @click="fillFromSebelumnya()"
              color="cyan"
              dark
              medium
              class="mb-2"
            >
              Ambil Data Sebelumnya
            </v-btn>
          </v-card-text>
          <v-card-text class="mt-0">
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_terapi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_terapi[i] = e)"
                  :value="data.inputan_terapi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="p" md="12" lg="12" sm="12">
                <p class="mt-2 mb-6">List Program Terapi</p>
                <template>
                  <v-simple-table dense class="elevation-5 text-left">
                    <thead>
                      <tr>
                        <th class="text-left" width="70%">Program</th>
                        <th class="text-left" width="10%">Tanggal Cabut</th>
                        <th class="text-center" colspan="2" width="10%">
                          Tanda Tangan
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in detail_program"
                        :key="data.item_id"
                      >
                        <td>
                          <v-text-field
                            outlined
                            dense
                            elevation="12"
                            v-model="data.item_program"
                            label="PROGRAM"
                            class="mt-2"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            outlined
                            dense
                            elevation="12"
                            type="date"
                            v-model="data.item_tanggal"
                            label="Tanggal Cabut"
                            class="mt-2"
                          ></v-text-field>
                        </td>

                        <td>
                          <v-checkbox
                            v-model="data.item_pasien"
                            label="PASIEN"
                            class="mt-0"
                          />
                          <v-checkbox
                            v-model="data.item_dokter"
                            label="DOKTER"
                            class="mt-0"
                          />
                          <v-checkbox
                            v-model="data.item_terapis"
                            label="TERAPIS"
                            class="mt-0"
                          />
                        </td>

                        <td>
                          <v-btn
                            @click="deleteItem(index)"
                            class="mt-2"
                            fab
                            color="error"
                            dense
                            outlined
                            x-small
                            elevation="2"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="1">
                          <v-btn
                            @click="addItem(null)"
                            class="my-4"
                            fab
                            color="success"
                            dense
                            outlined
                            x-small
                            elevation="2"
                          >
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </v-col>
              <!-- <v-col cols="12" class="p" md="9" lg="9" sm="9">
                        <p class="mt-2 mb-6">List Program Terapi</p>
                        <div class="mt-1">
                            <v-row v-for="(data, index) in detail_program" :key="data.item_id">
                                <v-col class="py-1" cols="3">
                                    <v-text-field outlined dense elevation="12" v-model="data.item_program"
                                        label="PROGRAM"></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="3">
                                    <v-text-field outlined dense elevation="12" type="date" v-model="data.item_tanggal"
                                        label="Tanggal Cabut"></v-text-field>
                                </v-col>
                                <v-checkbox v-model="data.item_pasien" label="PASIEN" class="mt-2" /> &nbsp; &nbsp;
                                <v-checkbox v-model="data.item_dokter" label="DOKTER" class="mt-2" /> &nbsp; &nbsp;
                                <v-checkbox v-model="data.item_terapis" label="TERAPIS" class="mt-2" /> &nbsp; &nbsp;
                                <v-col class="py-1" cols="1">
                                    <div class="justify-center">
                                        <v-btn @click="deleteItem(index)" class="mt-2" fab color="error" dense outlined
                                            x-small elevation="2">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <v-btn @click="addItem(null)" class="mt-0" fab color="success" dense outlined x-small
                            elevation="2">
                            <v-icon>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </v-col> -->
            </v-row>

            <div class="py-2"></div>
            <v-btn
              @click="dialogShow = true"
              color="primary"
              medium
              class="mb-10"
            >
              Simpan
            </v-btn>
            <!-- &nbsp;
            <v-btn
              @click="cetaknya('download')"
              color="primary"
              medium
              class="mb-10"
            >
              Download
            </v-btn>
            &nbsp;
            <v-btn @click="cetaknya('')" color="primary" medium class="mb-10">
              Lihat
            </v-btn> -->
            <dialog-confirm
              :show-dialog="dialogShow"
              :negative-button="dialogActionNBtn"
              :positive-button="dialogActionPBtn"
              :disabled-negative-btn="dialogDisableNBtn"
              :disabled-positive-btn="dialogDisablePBtn"
              :progress="dialogProgress"
              :title="dialogTitle"
              :message="dialogMessage"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" sm="6">
        <v-card class="p-3" elevation="5">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="6">
                <v-text-field
                  @blur="getProgramListTerapi"
                  v-model="searchTerapi"
                  dense
                  label="Pencarian"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="6">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" @click="initPermintaanPreviosPage"
                        >Previous</a
                      >
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        v-html="permintaan_pagination_container"
                      ></a>
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link"
                        @click="initPermintaanNextPage"
                        >Next</a
                      >
                    </li>
                  </ul>
                </nav>
              </v-col>
            </v-row>
            <div class="list-group" v-for="(itemList, i) of listTerapi" :key="i">
              <div
                class="list-group-item list-group-item-action flex-column align-items-start mb-1"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1">
                    {{ itemList.pemeriksaan.inputan_terapi.diagnosa }}
                  </h6>
                  <small>{{
                    itemList.created_at | moment("MMMM Do YYYY, h:mm:ss a")
                  }}</small>
                </div>
                <p class="mb-2">
                  {{
                    itemList.pemeriksaan.inputan_terapi.permintaan_terapi
                  }}
                </p>
                <div class="mb-2 mt-1">
                  <v-row>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn color="primary" class="btn-block" @click="copyItem(itemList)" medium dark>Copy</v-btn>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn color="red" class="btn-block" @click="cetaknya(itemList,'')"  medium dark>View</v-btn>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="4">
                      <v-btn color="green" class="btn-block" @click="cetaknya(itemList,'download')" medium dark>Download</v-btn>
                    </v-col>

                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snack_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Master from '../../data/lembar-program-terapi'
import SmartWidget from '@/components/SmartWidget.vue'
// import { mapActions, mapMutations } from 'vuex'
import Dialog from '@/components/Dialog'
import { mapActions } from 'vuex'
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  components: {
    SmartWidget,
    DialogConfirm: Dialog
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      snack_color: 'green',
      searchTerapi: '',
      permintaans_page: 1,
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',
      url_emr: 'http://emr.rs-syafira.com/',
      master: Master,
      id_generated: 0,
      detail_program: [],
      listTerapi: [],
      user: JSON.parse(localStorage.getItem('user')),
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: ''
        },
        inputan_terapi: {
          diagnosa: '',
          permintaan_terapi: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    // this.detail_program = []
    localStorage.removeItem('isProgramChange')
    if (this.$route.query.no_rm != null) {
      // console.log(this.master
      this.getDataPasien(this.$route.query.no_rm)

      this.getLembarProgramTerapi()
      this.getProgramListTerapi()
    }
  },
  methods: {
    ...mapActions(['postFormLembarProgramTerapi']),
    copyItem (item) {
      console.log(item)
      this.data.inputan_terapi = item.pemeriksaan.inputan_terapi
      // this.data.detail_program = response.detail_program
      // reload dynamic form
      var vue = this
      this.detail_program = []
      item.detail_program.forEach(function (item, index) {
        vue.addItem(item)
      })
    },
    getProgramListTerapi () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/list-terapi-by-pag',
        {
          rm: this.$route.query.no_rm,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          pls: this.searchTerapi
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          const pagination = results.pagination
          this.permintaan_pagination_container = pagination
          this.permintaans_total = results.total_pages
          this.listTerapi = results.list
          // console.log(response)
        },
        'JSON'
      )
    },
    cetaknya (item, view) {
      // console.log(item)
      window
        .open(
          baseUrlEmr +
            `cetak-word-emr/cetak-lembar-program-terapi?no_rm=${item.no_rm}&folio_id=${item.folio_id}&registration_id=${item.registration_id}&view=` +
            view,
          '_blank'
        )
        .focus()
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          console.log('HASIL RESPONSE ' + response)

          // this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
          // this.folioYangDipakai = JSON.parse(
          //   localStorage.getItem('folioIdYangDipakai')
          // )
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addItem (item) {
      console.log(item)
      localStorage.removeItem('isProgramChange')
      this.id_generated = this.id_generated + 1
      console.log(this.id_generated)
      console.log(this.id_generated)
      if (item === null) {
        this.detail_program.push({
          item_id: this.id_generated, // item.item_id,
          item_program: '', // item.item_program,
          item_tanggal: '',
          item_pasien: true,
          item_dokter: true,
          item_terapis: true,
          jumlah: 1
        })
      } else {
        this.detail_program.push({
          item_id: this.id_generated, // item.item_id,
          item_program: item.item_program,
          item_tanggal: item.item_tanggal,
          item_pasien: item.item_pasien,
          item_dokter: item.item_dokter,
          item_terapis: item.item_terapis,
          jumlah: item.jumlah
        })
      }

      localStorage.setItem(
        'isProgramChange',
        JSON.stringify(this.detail_program)
      )
    },
    deleteItem (item) {
      this.detail_program.splice(item, 1)
      localStorage.setItem(
        'isProgramChange',
        JSON.stringify(this.detail_program)
      )
    },
    postSave () {
      this.dialogProgress = true
      this.dialogDisableNBtn = true
      this.dialogDisablePBtn = true
      this.dialogMessage = 'Mohon tunggu sebentar, sedang menyimpan data...'
      console.log(this.data)
      localStorage.setItem(
        'form-lembar-program-terapi',
        JSON.stringify(this.data)
      )

      this.postFormLembarProgramTerapi({
        data: this.data,
        detail_program: this.detail_program,
        created_by: this.user.employee_id
      }).then((res) => {
        this.dialogProgress = false
        this.dialogMessage = res.msg
        this.getProgramListTerapi()
        setTimeout(() => {
          this.dialogShow = false
          // this.backButton()
          this.dialogDisableNBtn = false
          this.dialogDisablePBtn = false

          this.dialogMessage = 'Simpan Form Sekarang Ya?'
        }, 1000)
      })
    },
    getLembarProgramTerapi (v) {
      apiKedua
        .get(
          'emr/simpan-lembar-program-terapi?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          // console.log(response);

          if (response.pemeriksaan && response.detail_program) {
            this.data.inputan_terapi = response.pemeriksaan.inputan_terapi
            var vue = this
            response.detail_program.forEach(function (item, index) {
              vue.addItem(item)
            })
          }
          // this.data.detail_program = response.detail_program
          // reload dynamic form
        })
    },
    getLembarProgramTerapiSebelumnya (v) {
      apiKedua
        .get(
          'emr/get-sebelum-lembar-program-terapi?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // this.detail_program = []
          // console.log(res)
          const response = res.data.results
          // console.log(response)
          // console.log(response)
          // console.log(response)
          // console.log(response)
          // if (response.con === true) {
          if (res.data.con === true) {
            this.data.inputan_terapi = response.pemeriksaan.inputan_terapi
            // this.data.detail_program = response.detail_program
            // reload dynamic form
            var vue = this
            this.detail_program = []
            response.detail_program.forEach(function (item, index) {
              vue.addItem(item)
            })
          } else {
            // alert('data tidak ada')
          }
          setTimeout(() => {
            this.snack_text = res.data.msg
            this.snack_color = 'green'
            this.snackbar = true
            this.dialogShow = false
            // this.backButton()
            this.dialogDisableNBtn = false
            this.dialogDisablePBtn = false

            this.dialogMessage = 'Simpan Form Sekarang Ya?'
          }, 300)
        })
    },
    getKlaimRawatJalan (v) {
      apiKedua
        .get(
          'emr/simpan-klaim-rawat-jalan?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          console.log(res)
          if (res.data.con === true) {
            const response = res.data.results
            // console.log(response);
            this.data.inputan_terapi.diagnosa =
              response.inputan_dokter.pertama.diagnosa_medis
            this.data.inputan_terapi.permintaan_terapi =
              '' +
              response.inputan_dokter.tata_laksana_kfr.terapi_latihan +
              ', ' +
              response.inputan_dokter.tata_laksana_kfr.terapi_modalitas +
              ''
            // this.data.instrumen.hasil_yang_dapat = response.inputan_dokter.pertama.anamnesa
            // this.data.instrumen.kesimpulan = response.inputan_dokter.pertama.diagnosa_medis
            console.log(response)
          }

          setTimeout(() => {
            this.snack_text = res.data.msg
            this.snack_color = 'green'
            this.snackbar = true
            this.dialogShow = false
            // this.backButton()
            this.dialogDisableNBtn = false
            this.dialogDisablePBtn = false

            this.dialogMessage = 'Simpan Form Sekarang Ya?'
          }, 300)
        })
    },
    fillFromKlaim () {
      this.getKlaimRawatJalan(this.folioYangDipakai)
    },
    fillFromSebelumnya () {
      this.getLembarProgramTerapiSebelumnya(this.folioYangDipakai)
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getProgramListTerapi()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getProgramListTerapi()
    }
  }
}
</script>
